<template>
  <div style="width: 100%; margin-left: 1rem; margin-right: 1rem" class="row">
    <div class="col-sm-12 my-auto card" v-if="storeProfile && !loading">
      <a-form :form="form" @submit.prevent="submit">
        <div class="mt-3">
          <a-form-item
            :label-col="{
              lg: { span: 3 }
            }"
            :wrapper-col="{
              lg: { span: 20 }
            }"
            :label="$t('store.fotoProfile')"
            labelAlign="left"
          >
            <div v-if="image.length">
              <img :src="image[0].url" style="width: 150px; height: 150px"/>
            </div>
            <div v-else>
              <div v-if="storeProfile.store_data">
                <img v-if="storeProfile.store_data.store_profile_url" :src="`${storeProfile.store_data.store_profile_url}?tr=w-100,h-100,cm-pad_resize,bg-FFFFFF`" style="width: 150px; height: 150px"/>
              </div>
            </div>
            <div>
              <a-upload
                :show-upload-list="false"
                name="image"
                :beforeUpload="() => false"
                @change="addImage"
                v-decorator="[
                  'image'
                ]"
              >
                <div>
                  <a-button size="small" icon="plus">
                    {{ !!image.length || storeProfile.store_data.store_profile_url ? $t('store.changeImage') : $t('store.addImage') }}
                  </a-button>
                </div>
              </a-upload>
            </div>
          </a-form-item>
        </div>
        <div class="mt-3">
          <a-form-item
            :label-col="{
              lg: { span: 3 }
            }"
            :wrapper-col="{
              lg: { span: 20 }
            }"
            :label="$t('store.storeName')"
            labelAlign="left"
          >
            <a-input
              v-decorator="[
                'storename',
                {
                  rules: [{
                    required: true, message: $t('store.requiredStoreName')
                  }],
                  initialValue: initialValueStoreName
                },
              ]"
              :placeholder="$t('store.requiredStoreName')"
            />
          </a-form-item>
          <a-form-item
            :label-col="{
              lg: { span: 3 }
            }"
            :wrapper-col="{
              lg: { span: 10 }
            }"
            :label="$t('store.storeUrl')"
            labelAlign="left"
          >
            <!-- <a-input
              v-decorator="[
                'storeurl',
                {
                  initialValue: store_url
                },
              ]"
              :disabled="true"
              style="background-color: white; color: black;"
            /> -->
            <div v-b-tooltip.hover.topleft="$t('store.notifStoreUrl')">
              {{ store_url }}
            </div>
          </a-form-item>
          <!-- <a-form-item v-if="!isInitialValue" class="mt-4">
            <a-button
              type="primary"
              size="large"
              class="text-center mr-2"
              htmlType="submit"
            >
              <strong>Submit</strong>
            </a-button>
            <a-button
              size="large"
              class="text-center ml-2"
              @click.prevent="editProfile(true)"
            >
              <strong>Cancel</strong>
            </a-button>
          </a-form-item> -->
          <a-form-item class="mt-4">
            <a-button
              size="large"
              class="text-center mr-2"
              htmlType="submit"
            >
              <strong> {{ $t('store.save') }} </strong>
            </a-button>
          </a-form-item>
        </div>
      </a-form>
      <!-- <div class="mb-3" v-if="isInitialValue">
        <a-button type="primary" @click.prevent="editProfile(false)">Edit Profile</a-button>
      </div> -->
    </div>
    <div class="vld-parent">
      <loading-overlay
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="30"
        :width="30"
      ></loading-overlay>
    </div>
  </div>
</template>

<script>
import { getImagePreview } from '@/services/image/utils'
import { mapState } from 'vuex'
import LoadingOverlay from 'vue-loading-overlay'

export default {
  components: {
    LoadingOverlay,
  },
  name: 'StoreProfileSetting',
  data () {
    return {
      form: this.$form.createForm(this),
      image: [],
      isInitialValue: true,
      initialValueStoreName: this.storeProfile?.store_data ? this.storeProfile.store_data.name : null,
    }
  },
  methods: {
    submit () {
      this.form.validateFieldsAndScroll((err, values) => {
        if (err) return
        if (this.image.length || this.storeProfile.store_data?.store_profile_url) {
          this.$store.dispatch('storeSetting/EDITSTOREPROFILE', { image: this.image, ...values })
            .then(({ data }) => {
              this.isInitialValue = true
              this.initialValueStoreName = data.store_data.name
              this.$notification.success({
                message: this.$t('store.successEditStore'),
              })
            })
            .catch(err => {
              this.$notification.error({
                message: this.$t('store.failedEditStore'),
                description: err.response?.data?.message || err.message,
              })
            })
        } else {
          this.$notification.error({
            message: this.$t('store.requiredStoreFoto'),
          })
        }
      })
    },
    editProfile (param) {
      this.isInitialValue = param
      if (param) {
        this.form.resetFields()
        this.image = []
      }
    },
    addImage ({ file }) {
      getImagePreview(file)
        .then(url => {
          const data = {
            file,
            url,
          }
          const image = [data]
          this.image = image
        })
    },
  },
  created () {
    this.$store.dispatch('storeSetting/GETPROFILE')
  },
  computed: {
    ...mapState('storeSetting', {
      storeProfile: state => state.storeProfile,
      loading: state => state.loading,
    }),
    ...mapState('user', {
      store_url: state => state.store_url,
    }),
  },
  watch: {
    storeProfile: function (val) {
      this.initialValueStoreName = val.store_data.name
    },
  },
}
</script>

<template>
  <store-settings />
</template>

<script>
import StoreSettings from '@/components/StoreSetting'

export default {
  components: {
    StoreSettings,
  },
}
</script>

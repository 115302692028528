<template>
  <div style="width: 100%; margin-left: 1rem; margin-right: 1rem" class="row">
    <div class="col-sm-12 my-auto card">
      <div class="mt-3 ml-2 mb-3">
        <div style="font-size: 25px" class="mb-2"><strong> Selected Banner: </strong></div>
        <img slot="cover" :src="selectedImage()" />
      </div>
    </div>
    <a-tabs />
    <div class="card col-sm-12 mt-3">
      <div style="font-size: 25px" class="mb-2 mt-3"><strong> Available Banner: </strong></div>
      <div
        class="d-flex align-items-center justify-content-around mt-3"
        v-for="(image, index) in banners"
        :key="index"
      >
        <div>
          <a-radio-group v-model="radioValue">
            <a-radio :value="index" @change="changeBanner(image)" />
          </a-radio-group>
        </div>
        <div class="image-item mb-2" @click.prevent="changeBanner(image)" style="max-width: 90%;">
          <img slot="cover" :src="`${image.url}`"/>
        </div>
      </div>
        <!-- <a-col
          v-for="(image, index) in banners"
          :xs="24"
          :md="12"
          :key="index"
          class="image-col mb-3 mt-3"
          style="cursor: pointer;"
        >
          <a-radio-group v-model="radioValue">
            <a-radio :value="index" @change="changeBanner(image)">
              <div class="image-item" :id="`image_${index}`" @click.prevent="changeBanner(image)">
                <img slot="cover" :src="`${image.url}`" />
              </div>
            </a-radio>
          </a-radio-group>
          <b-tooltip :target="`image_${index}`" triggers="hover">
            Select this banner
          </b-tooltip>
        </a-col> -->
    </div>
    <div class="vld-parent">
      <loading-overlay
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="30"
        :width="30"
      ></loading-overlay>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LoadingOverlay from 'vue-loading-overlay'

export default {
  components: {
    LoadingOverlay,
  },
  methods: {
    selectedImage () {
      if (this.banners.length) {
        const selectImage = this.banners.filter(item => !!item.is_selected)
        return selectImage.length ? selectImage[0].url : null
      }
      return null
    },
    changeBanner (image) {
      const image_data = this.banners.map(item => {
        if (item.ref_id === image.ref_id) {
          return {
            ref_id: item.ref_id,
            is_selected: true,
          }
        } else {
          return {
            ref_id: item.ref_id,
            is_selected: false,
          }
        }
      })
      this.$store.dispatch('storeSetting/CHANGEBANNER', { image_data })
        .then(_ => {
          this.$notification.success({
            message: 'Change banner success',
          })
        })
        .catch(err => {
          this.$notification.error({
            message: 'Change banner failed',
            description: err.response?.data?.message || err.message,
          })
        })
    },
  },
  beforeCreate () {
    this.$store.dispatch('storeSetting/GETBANNER')
  },
  computed: {
    ...mapState('storeSetting', {
      banners: state => state.bannerList,
      loading: state => state.loading,
    }),
    radioValue: {
      get: function () {
        if (this.banners) {
          const index = this.banners.findIndex(item => !!item.is_selected)
          return index
        }
        return null
      },
      set: function () {
        if (this.banners) {
          const index = this.banners.findIndex(item => !!item.is_selected)
          return index
        }
        return null
      },
    },
  },
}
</script>

<style scoped lang="scss">
img {
  max-width: 100%
}

.image-col {
  display: flex;
  justify-items: center;
}

.image-action {
  position: absolute;
  top: .5rem;
  right: .5rem;
  opacity: .6;
  transition: all .2s ease-in-out;
}

.image-item {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #efefef;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  &:hover {
    .image-action {
      opacity: 1;
    }
  }
}
</style>

<template>
  <div class="row">
    <div style="width: 100%; margin-left: 1rem; margin-right: 1rem">
      <a-tabs :default-active-key="defaultTabActive" @change="handleChangeTab">
        <a-tab-pane :key="1" :tab="$t('store.profileSetting')" />
        <a-tab-pane :key="2" :tab="$t('store.bannerSetting')" />
      </a-tabs>
    </div>
    <profile-store-setting v-if="defaultTabActive === 1" />
    <banner-setting v-if="defaultTabActive === 2" />
  </div>
</template>

<script>
import BannerSetting from './BannerSetting'
import ProfileStoreSetting from './ProfileSetting'

export default {
  components: {
    BannerSetting,
    ProfileStoreSetting,
  },
  data () {
    return {
      defaultTabActive: 1,
    }
  },
  methods: {
    handleChangeTab (value) {
      this.defaultTabActive = value
    },
  },
}
</script>

<style>

</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",staticStyle:{"width":"100%","margin-left":"1rem","margin-right":"1rem"}},[(_vm.storeProfile && !_vm.loading)?_c('div',{staticClass:"col-sm-12 my-auto card"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"mt-3"},[_c('a-form-item',{attrs:{"label-col":{
            lg: { span: 3 }
          },"wrapper-col":{
            lg: { span: 20 }
          },"label":_vm.$t('store.fotoProfile'),"labelAlign":"left"}},[(_vm.image.length)?_c('div',[_c('img',{staticStyle:{"width":"150px","height":"150px"},attrs:{"src":_vm.image[0].url}})]):_c('div',[(_vm.storeProfile.store_data)?_c('div',[(_vm.storeProfile.store_data.store_profile_url)?_c('img',{staticStyle:{"width":"150px","height":"150px"},attrs:{"src":`${_vm.storeProfile.store_data.store_profile_url}?tr=w-100,h-100,cm-pad_resize,bg-FFFFFF`}}):_vm._e()]):_vm._e()]),_c('div',[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'image'
              ]),expression:"[\n                'image'\n              ]"}],attrs:{"show-upload-list":false,"name":"image","beforeUpload":() => false},on:{"change":_vm.addImage}},[_c('div',[_c('a-button',{attrs:{"size":"small","icon":"plus"}},[_vm._v(" "+_vm._s(!!_vm.image.length || _vm.storeProfile.store_data.store_profile_url ? _vm.$t('store.changeImage') : _vm.$t('store.addImage'))+" ")])],1)])],1)])],1),_c('div',{staticClass:"mt-3"},[_c('a-form-item',{attrs:{"label-col":{
            lg: { span: 3 }
          },"wrapper-col":{
            lg: { span: 20 }
          },"label":_vm.$t('store.storeName'),"labelAlign":"left"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'storename',
              {
                rules: [{
                  required: true, message: _vm.$t('store.requiredStoreName')
                }],
                initialValue: _vm.initialValueStoreName
              },
            ]),expression:"[\n              'storename',\n              {\n                rules: [{\n                  required: true, message: $t('store.requiredStoreName')\n                }],\n                initialValue: initialValueStoreName\n              },\n            ]"}],attrs:{"placeholder":_vm.$t('store.requiredStoreName')}})],1),_c('a-form-item',{attrs:{"label-col":{
            lg: { span: 3 }
          },"wrapper-col":{
            lg: { span: 10 }
          },"label":_vm.$t('store.storeUrl'),"labelAlign":"left"}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topleft",value:(_vm.$t('store.notifStoreUrl')),expression:"$t('store.notifStoreUrl')",modifiers:{"hover":true,"topleft":true}}]},[_vm._v(" "+_vm._s(_vm.store_url)+" ")])]),_c('a-form-item',{staticClass:"mt-4"},[_c('a-button',{staticClass:"text-center mr-2",attrs:{"size":"large","htmlType":"submit"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t('store.save'))+" ")])])],1)],1)])],1):_vm._e(),_c('div',{staticClass:"vld-parent"},[_c('loading-overlay',{attrs:{"active":_vm.loading,"can-cancel":false,"is-full-page":true,"height":30,"width":30},on:{"update:active":function($event){_vm.loading=$event}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }